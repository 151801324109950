import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {AuthService} from "../../../core/services/auth.service";
import  { Router} from "@angular/router";
import {FormBuilder, FormGroup} from "@angular/forms";
import {SessionLoginService} from "../../../core/services/session-login.service";
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from "@angular/material/snack-bar";

import { Inject } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {getConfService} from "../../../core/services/getConf.service";


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})


export class LoginComponent implements OnInit {
  loading = false;
  mainForm!: FormGroup;
  emailForm!: FormGroup
  wrongCreadentials = false


  email = "";
  password = "";

  constructor(
    private auth: AuthService,
    private router: Router,
    private formBuilder: FormBuilder,
    private sessionLogin: SessionLoginService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private confService: getConfService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ){ }

  ngOnInit(): void {
    this.initMainForm();
  }

  private initMainForm(): void {
    this.mainForm = this.formBuilder.group({
      email: this.email,
      password: this.password,
    });
  }

  lastUpdate! : string;
  onLogin(): void {
    this.loading = true;
    this.wrongCreadentials = false;

    this.dialog.closeAll();

    this.confService.getData();

    this.sessionLogin.login(this.email, this.password).subscribe(result => {
      this.loading = false;
      this.confService.getData();

        this.dialog.closeAll();
        setTimeout(() => {
          this.openSnackBar();
        }, 500);


    }, error => {
      this.wrongCreadentials = true;
      this.dialog.open(LoginComponent, {
        data: {
          wrongCreadentials: this.wrongCreadentials
        }
      });

    });


  }

  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  openSnackBar() {
    this._snackBar.open("Vous êtes maintenant identifié !", '', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 2000
    });
  }


}
