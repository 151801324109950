


<div *ngIf="items$ | async;">
<mat-grid-list cols="1" class="grille" [gutterSize]="'10px'" rowHeight="2:2">
  <mat-grid-tile
    *ngFor="let product of (items$ | async); let i = index"
    (click)="open(product)"
    [colspan]="1"
    [rowspan]="1"
    class="pinkBorder"
  >
    <img
      src="{{product.default_image.image300Url}}"
      width="100%"/>
  </mat-grid-tile>
</mat-grid-list>



</div>


