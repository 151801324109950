import { ProductModel } from "../../products-categories/models/product.model";
import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})

export class LoaderService {
  private _loading$ = new BehaviorSubject<boolean>(false);
  get loading$(): Observable<boolean> {
    return this._loading$.asObservable();
  }

  private setLoadingStatus(loading: boolean) {
    this._loading$.next(loading);
  }

  showLoader() {
    this.setLoadingStatus(true);
  }

  hideLoader() {
    this.setLoadingStatus(false);
  }

}
