import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {CartComponent} from "./cart/cart.component";

const routes: Routes = [
  {
    path: 'browse' , loadChildren:() => import('./products-categories/products-categories.module').then(m => m.ProductsCategoriesModule)
  },

  {
    path: '' , loadChildren:() => import('./products-categories/products-categories.module').then(m => m.ProductsCategoriesModule)
  },

  { path: 'cart', component: CartComponent },

  {path: '**', redirectTo: 'browse'}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

