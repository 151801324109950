

<mat-card [formGroup]="mainForm" class="main-form login-form">

  <div *ngIf="!loading">
  <div class="form-card " >
    <mat-card-subtitle>Informations personnelles</mat-card-subtitle>
    <div class="form-row">
      <mat-error *ngIf="data.wrongCreadentials">Email ou mot de passe invalide !</mat-error>

      <div>
        <label>Email</label>
        <input type="email"   formControlName="email"   [(ngModel)]="email" value="user@test.com">
      </div>

      <div>
        <label>Mot de passe</label>
        <input type="password"  formControlName="password" [(ngModel)]="password" value="123456">
      </div>
    </div>
  </div>

  <mat-card-actions *ngIf="mainForm.valid">
    <button mat-flat-button
            (click)="onLogin()"
            [disabled]="loading">S'identifier</button>
  </mat-card-actions>


  </div>

  <mat-spinner *ngIf="loading" color="primary" mode="indeterminate"></mat-spinner>


</mat-card>
