import { ProductModel } from "../../products-categories/models/product.model";
import {Injectable, ViewChild} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {MatSidenav} from "@angular/material/sidenav";

@Injectable({
  providedIn: 'root'
})

export class CartService {

  public drawercart!: MatSidenav;

  items: ProductModel[] = [];

  //Count items
  //itemsInCart: BehaviorSubject<number> = new BehaviorSubject(0);
  itemsInCart$: BehaviorSubject<ProductModel[]> = new BehaviorSubject<ProductModel[]>([]);
  itemsInCartCount$: Observable<number>;


  constructor() {
    this.itemsInCartCount$ = this.itemsInCart$.pipe(map(items => items?.length ?? 0));

    this.itemsInCartCount$.subscribe(count => {
      if(count==0 && this.drawercart)  {
        this.drawercart.close();
      }
    })

  }

  addToCart(product: ProductModel) {
    this.loadCart();
    let its = this.getItems();
    if(its) {
      this.items = its;
    }

    this.items.push(product);
    this.saveCart();

    this.updateCountItems();

  }

  countItems() {
    return this.getItems()?.length ?? 0;
  }

  //Send to
  updateCountItems() {
    this.itemsInCart$.next(this.getItems());
  }

  getItems() {
    let items = localStorage.getItem('cart_items') as string;
    if(items == '[]') {
      //Remove local storage
      this.clearCart();
    }

    return JSON.parse(localStorage.getItem('cart_items') as string);
  }


  clearCart() {
    this.items = [];
    localStorage.removeItem("cart_items")
    this.updateCountItems();
    return this.items;
  }

  loadCart(): void {
    return JSON.parse(localStorage.getItem('cart_items') as string);
  }

  saveCart(): void {

    localStorage.setItem('cart_items', JSON.stringify(this.items));
  }

  itemInCart(item:any) {
    let items = this.getItems();
    let exist = false;
    if(items) {
      for (let o of items) {
        if (o.id == item.id) {
          exist = true
        }
      }
    }

    return exist;
  }

  deleteItemInCart(item:any) {
    let items = this.getItems();
    if(items) {
      let i = 0;
      for (let o of items) {
        if (o.id == item.id) {
          items.splice(i, 1);
        }
        i++;
      }
    }

    this.loadCart();
    this.items = items;
    this.saveCart();
    this.updateCountItems();
  }


}
