import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject, Observable} from "rxjs";
import {CategoryModel} from "../models/category.model";
import {environment} from "../../../environments/environment";
import {delay, map, switchMap, take, tap} from "rxjs/operators";

@Injectable()
export class CategoriesService {
  constructor(public http: HttpClient) { }

  /* Delais for query */
  private lastTimeCategoriesLoaded = 0;


  /* Loading funcs */
  private _loading$ = new BehaviorSubject<boolean>(false);
  get loading$(): Observable<boolean> {
    return this._loading$.asObservable();
  }

  /* Observable for categories */
  public _categories$ = new BehaviorSubject<CategoryModel[]>([])
  get categories$(): Observable<CategoryModel[]> {
    return this._categories$.asObservable();
  }

  public setLoadingStatus(loading: boolean) {
    this._loading$.next(loading);
  }

  cleanCategories() {
    this._categories$.next([])
  }

  getCategoryFromServer(id?: any) {

    if(!id) {
      id = environment.mainCategory
    }

    //Show loading
    this.setLoadingStatus(true);

    let loggedIn = localStorage.getItem('loggedIn');
    let url = environment.apiUrl;
    if(loggedIn == 'true') {
      url = environment.apiUrl
    }



    this.http.get<CategoryModel[]>(`${url}/categories/${id}`).pipe(
      delay(1000),
      tap(categories => {

        //In in categories
        this._categories$.next(categories);
        console.log(categories);

        //Hide loading
        this.setLoadingStatus(false);

        this.lastTimeCategoriesLoaded = Date.now();

      })
    ).subscribe();

  }


  getCategoryFromServerMariage(id?: any) {

    //300 000 = 5 minutes
    /*
    if (Date.now() - this.lastTimeCategoriesLoaded <= 300000) {
      return;
    }

     */

    if(!id) {
      id = 12;
    }

    //Show loading
    this.setLoadingStatus(true);

    let loggedIn = localStorage.getItem('loggedIn');
    let url = environment.baseUrl;
    if(loggedIn == 'true') {
      url = environment.apiUrl
    }


    this.http.get<CategoryModel[]>(`${url}/categories/${id}`).pipe(
      delay(1000),
      tap(categories => {

        //In in categories
        this._categories$.next(categories);
        console.log(categories);

        //Hide loading
        this.setLoadingStatus(false);

        this.lastTimeCategoriesLoaded = Date.now();

      })
    ).subscribe();

  }

  getCategoryById(id: number): Observable<CategoryModel> {

    if (!this.lastTimeCategoriesLoaded) {
      this.getCategoryFromServer();
    }

    return this.categories$.pipe(
      map(categories => categories.filter(category => category.id === id)[0])
    );
  }


  deleteCategory(id: number): void {
    this.setLoadingStatus(true);
    //this.http.delete(`${environment.apiUrl}/categories/delete/${id}`).pipe(
    this.http.get(`${environment.apiUrl}/categories/delete/${id}`).pipe(
      delay(1000),
      switchMap(() => this.categories$),
      take(1),
      map(categories => categories.filter(category => category.id !== id)),
      tap(categories => {
        this._categories$.next(categories);
        this.setLoadingStatus(false);
      })
    ).subscribe();

  }

  changeNameToCategory(id: number): void {
    this.categories$.pipe(
      take(1),
      map(categories => categories
        .map(category => category.id === id ?
          { ...category, name: 'Test Name' } :
          category
        )
      ),
      tap(updatedcategories => this._categories$.next(updatedcategories)),
      switchMap(updatedcategories =>
        this.http.patch(`${environment.apiUrl}/categories/updateName/${id}`,
          updatedcategories.find(category => category.id === id))
      )
    ).subscribe();
  }



}
