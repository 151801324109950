import {NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {BrowserModule, HammerModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {CoreModule} from "./core/core.module";
import {SharedModule} from "./shared/shared.module";
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AuthModule} from "./auth/auth.module";
import {HTTP_INTERCEPTORS, HttpClientXsrfModule} from "@angular/common/http";
import {HttpXsrfInterceptorService} from "./core/interceptors/http-xsrf-interceptor/http-xsrf-interceptor.service";
import {ProductsService} from "./products-categories/services/products.service";
import {LightgalleryModule} from "lightgallery/angular";
import {CategoriesService} from "./products-categories/services/categories.service";
import {BackButtonDirective} from "./core/directives/back-button.directive";
import {NavigationService} from "./core/services/navigation.service";
import { CartComponent } from './cart/cart.component';
import { DialogComponent } from './cart/components/dialog/dialog.component';
import { SplashScreenComponent} from "./core/components/splash-screen/splash-screen.component";
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {getConfService} from "./core/services/getConf.service";
import {updateService} from "./core/services/update.service";
import {NgxJsonViewerModule} from "ngx-json-viewer";
import {MatSidenavModule} from "@angular/material/sidenav";

@NgModule({
  declarations: [
    AppComponent,
    BackButtonDirective,
    CartComponent,
    DialogComponent,
    SplashScreenComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    SharedModule,
    NgbModule,
    AuthModule,
    LightgalleryModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'token'
    }),
    HammerModule,
    FontAwesomeModule,

    NgxJsonViewerModule,

    MatSidenavModule,



  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpXsrfInterceptorService, multi: true},
    ProductsService,
    CategoriesService,
    NavigationService,
    getConfService,
    updateService
  ],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA]
})
export class AppModule {







}
