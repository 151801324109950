<!--<splash-screen [duration]="2"></splash-screen>-->

<mat-sidenav-container fullscreen  autosize [hasBackdrop]="false">
  <mat-toolbar class="fixednavbar" color="primary">

    <a href="#" style="display: none;"></a>

    <button mat-icon-button (click)="drawer.toggle()">
      <mat-icon>menu</mat-icon>
    </button>
    <span></span>
    <span class="example-spacer"></span>

    <a (click)="drawercart.toggle()" *ngIf="hasItems | async" [ngClass]="{ 'pink' : hasItems | async }" mat-icon-button
       class="example-icon favorite-icon" aria-label="Example icon-button with heart icon">
      <mat-icon>favorite</mat-icon>
    </a>


    <button (click)="navigation.back()" mat-icon-button class="example-icon"
            aria-label="Example icon-button with share icon">
      <mat-icon>cached</mat-icon>
    </button>
  </mat-toolbar>
  <mat-sidenav #drawer mode="over" class="sidebar" [fixedInViewport]="options.value.fixed" [fixedTopGap]="options.value.top" [fixedBottomGap]="options.value.bottom">


    <button (click)="drawer.toggle()" class="closeRight">
      <mat-icon aria-hidden="false" aria-label="Fermer" fontIcon="close"></mat-icon>
    </button>

    <ul>
      <li class="homeLink"><a routerLink="" (click)="drawer.toggle()">
        ACCUEIL</a></li>

      <li *ngFor="let category of obj">
        <a mat-list-item routerLink="browse/categories/{{category.id}}" *ngIf="category.id != 507"
           (click)="drawer.toggle()">{{ category.name }}</a>
      </li>

      <!--
      <li class="sep"><a routerLink="#"   (click)="drawercart.toggle()"  ><mat-icon [ngClass]="{ 'pink' : hasItems | async }">favorite</mat-icon> Mes favoris</a></li>
      -->

      <li class="login" *ngIf='(appUrl == "book.chris-alexxa.com") && !(session.isLoggedIn$ | async)'><a [routerLink]="" (click)="openBottomSheetLogin();" >
        Mon compte</a></li>


      <li class="login" *ngIf='(appUrl == "book.chris-alexxa.com") &&  (session.isLoggedIn$ | async)'><a [routerLink]="" (click)="logOut();" >
        Fermer ma session</a></li>

    </ul>

  </mat-sidenav>


  <mat-sidenav #drawercart   mode="side" position="end" class="sidebarright" [fixedInViewport]="options.value.fixed" [fixedTopGap]="options.value.top" [fixedBottomGap]="options.value.bottom">
    <div class="position-relative" style="max-width: 180px; position: relative">
      <div class="topCart">
        <h3>
          <mat-icon class="pink">favorite</mat-icon> Mes favoris
        </h3>
      </div>

      <button (click)="drawercart.toggle()" #closeright class="closeRight">
        <mat-icon aria-hidden="false" aria-label="Fermer" fontIcon="close"></mat-icon>
      </button>

      <button  (click)="confirmDialogDeleteAll();"  class="deleteBtn">
        <mat-icon aria-hidden="false" fontIcon="delete"></mat-icon>
      </button>

      <app-cart></app-cart>
    </div>
  </mat-sidenav>


  <div>
    <div class="main-content" [@fadeAnimation]="outlet.isActivated ? outlet.activatedRoute : ''">

      <header class="header">
        <a routerLink="/"><img src="assets/images/menu-logo-desktop-white.svg" width="200"/></a>
      </header>


      <router-outlet #outlet="outlet"></router-outlet>

      <div *ngIf='session.isLoggedIn$ | async' class="lastUpdate">
        MAJ {{getConfService.conf$ | async }}

        {{theme  | json}}
      </div>

    </div>
  </div>

</mat-sidenav-container>
