import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../environments/environment";
import {fadeAnimation} from "./shared/animations/fade.animation";
import {NavigationService} from "./core/services/navigation.service";
import {Router} from "@angular/router";
import {CartComponent} from "./cart/cart.component";
import {MatBottomSheet, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {CartService} from "./core/services/cart.service";
import {FormBuilder} from "@angular/forms";
import {LoginComponent} from "./auth/components/login/login.component";
import {MatDialog} from "@angular/material/dialog";
import {SessionLoginService} from "./core/services/session-login.service";
import { Observable, Subscription, BehaviorSubject} from "rxjs";
import {map} from "rxjs/operators";
import {DialogComponent} from "./cart/components/dialog/dialog.component";
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from "@angular/material/snack-bar";
import {MatSidenav} from "@angular/material/sidenav";
import {getConfService} from "./core/services/getConf.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [fadeAnimation],
})



export class AppComponent implements OnInit, AfterViewInit {
  title = 'cris-alexxa';
  obj!: any
  currentRoute: any;

  @ViewChild('closeright') closeright!: ElementRef;
  @ViewChild('rightSidenav') public sidenav!: MatSidenav;
  @ViewChild('drawercart') public drawercart!: MatSidenav;



  hasItems: Observable<boolean>;
  subscription!: Subscription;

  options = this._formBuilder.group({
    bottom: 0,
    fixed: true,
    top: 0,
  });

  constructor(
    private http: HttpClient,
    public navigation: NavigationService,
    private  router: Router,
    private bottomSheet: MatBottomSheet,
    private cartService: CartService,
    private _formBuilder: FormBuilder,
    public dialog: MatDialog,
    public session: SessionLoginService,
    public dialogo: MatDialog,
    private _snackBar: MatSnackBar,
    public getConfService : getConfService
  ) {
    //this.hasItems = this.cartService.itemsInCart.pipe(map(result => !!result));
    this.hasItems = this.cartService.itemsInCartCount$.pipe(map(result => !!result));
  }
  theme!: string;
  appUrl!: string;
  ngOnInit(): void {

    this.appUrl = window.location.hostname;
    this.currentRoute = this.router.url;

    let loggedIn = localStorage.getItem('loggedIn');
    let url = environment.baseUrl;
    if(loggedIn == 'true') {
      url = environment.apiUrl
    }

    //Call webservice for get main categories
    this.http.get(`${url}/categories/${environment.mainCategory}`).subscribe(response => {
      this.obj = response;
    });

    this.cartService.updateCountItems();

    this.getConfService.getData();
    /*
    this.getConfService.conf$.subscribe(data => {
      return this.getConfService.conf$.next(data)
    })
     */



  }

  public lastUpdate!:string


  ngAfterViewInit() {
      this.cartService.drawercart = this.drawercart;
  }

  openBottomSheet(): void {
    this.bottomSheet.open(CartComponent);
  }


  openBottomSheetLogin(): void {
    this.dialog.open(LoginComponent, {
      data: {
        wrongCreadentials: null
      }
    });
  }

  logOut(): void {
    this.session.logout()
  }


  confirmDialogDeleteAll(): void {
    this.dialogo
      .open(DialogComponent, {
        data: 'Vous êtes sur le point de vider vos favoris'
      })
      .afterClosed()
      .subscribe((confirmDelete: Boolean) => {
        if (confirmDelete) {

          //this.drawercart.close();
          //this.closeright.nativeElement.click();
          this.deleteAll();
        }
      });
  }

  deleteAll() {
    setTimeout(() => {
      this.bottomSheet.dismiss();
      setTimeout(() => {
        this.cartService.clearCart();
        this.openSnackBarDeleteAll();
      }, 500);
    }, 500);

  }


  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  openSnackBarDeleteAll() {
    this._snackBar.open("Vos favoris ont été vidés avec succès !", '', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 2000
    });
  }



}
