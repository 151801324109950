import { Injectable} from "@angular/core";
import {HttpEvent, HttpHandler, HttpRequest, HttpXsrfTokenExtractor} from "@angular/common/http";
import {Observable} from "rxjs";


@Injectable({
  providedIn: 'root'
})

export class HttpXsrfInterceptorService {
  constructor(
    private tokenExtractor: HttpXsrfTokenExtractor
  ) { }




  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      const headerName = "Authorization";
      //const token = this.tokenExtractor.getToken() as string;
      const token = localStorage.getItem('token');


      if (token != null && !req.headers.has(headerName)) {
        req = req.clone({
          headers: req.headers.set(headerName, "Bearer " + token),
        })
      }

    return next.handle(req)
  }





}
