import { Injectable } from '@angular/core';
import {HttpClient } from '@angular/common/http';
import {BehaviorSubject} from "rxjs";
import {environment} from "../../../environments/environment";
import {map} from "rxjs/operators";
import {Observable} from "rxjs";

@Injectable()

 export class getConfService  {
  public conf$: BehaviorSubject<string>;

  constructor(public http: HttpClient) {
    this.conf$ = new BehaviorSubject('');
  }

  getData() {
    const url = 'https://api.chris-alexxa.app-services.link/api/getConf.json';
      this.http.get(url).pipe(map((response: any) => {
        return this.conf$.next(response.lastTime);

      })).subscribe(result => {

      }, error => {

      })
  }
}
