import { Injectable} from "@angular/core";
import { HttpClientModule } from '@angular/common/http';
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  constructor(private http: HttpClient) {}

  private token!: string;


  login(): void {
    this.token = "myFakeToken";
  }

  getToken() : string {
    return this.token;
  }

}
