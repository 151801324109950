// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,

  //Define all url
  apiUrl: 'https://api.chris-alexxa.app-services.link/api',
  baseUrl: 'https://api.chris-alexxa.app-services.link',
  baseLoginUrl: 'https://api.chris-alexxa.app-services.link/api',

  //Fet default category
  mainCategory: '0'

};
