import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {map} from "rxjs/operators";
import {Subject} from "rxjs";
import {BehaviorSubject} from "rxjs";
import {Router} from "@angular/router";
import {getConfService} from "./getConf.service";

@Injectable({
  providedIn: 'root'
})

export class SessionLoginService {

  LOGIN_URL = '/login';
  public isLoggedIn$: BehaviorSubject<boolean>;

  constructor(private http: HttpClient, private router : Router, public confService : getConfService) {
    const isLoggedIn = localStorage.getItem('loggedIn') === 'true';
    this.isLoggedIn$ = new BehaviorSubject(isLoggedIn);
  }

  login(pEmail: string, pPassword: string) {
    const loginData = {
      email: pEmail,
      password: pPassword,
    }
    return new Observable<boolean>((observer) => {
      this.http.post(environment.baseLoginUrl + this.LOGIN_URL, loginData).pipe(map((response: any) => {
        localStorage.setItem('token', response.data.token);
      })).subscribe(result => {
        observer.next(true)
        observer.complete();

        this.confService.getData();

        localStorage.setItem('loggedIn', 'true');
        this.isLoggedIn$.next(true);


      }, error => {
        observer.error(false);
        observer.complete()
      })
    })
  }

  logout() {
    //localStorage.removeItem("cart_items")
    localStorage.setItem('loggedIn', 'false');
    localStorage.removeItem('token');
    this.isLoggedIn$.next(false);

    this.router.navigate(['/categories']);

  }

}
