import {Component, ElementRef, NgModule, ViewChild, ViewEncapsulation} from '@angular/core';
import {Observable} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {ProductsService} from "../../services/products.service";
import {NgbActiveModal, NgbModal, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import SwiperCore, {FreeMode, Navigation, Pagination, Thumbs, Zoom} from "swiper";
import {PlatformLocation} from "@angular/common";
import 'hammerjs';
import {CartService} from "../../../core/services/cart.service";
import {ProductModel} from "../../models/product.model";
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from "@angular/material/snack-bar";
import {map} from "rxjs/operators";
import {LoaderService} from "../../../core/services/loader.service";
import {BeforeSlideDetail} from "lightgallery/lg-events";
import lgZoom from 'lightgallery/plugins/zoom';
import {MatDrawer} from "@angular/material/sidenav";

SwiperCore.use([Zoom, FreeMode, Navigation, Pagination, Thumbs]);


declare const $: any;

/**
 * @title Dialog Animations
 */
@Component({
  selector: 'product-details',
  styleUrls: ['product-details.component.scss'],
  templateUrl: 'product-details.component.html',
  encapsulation: ViewEncapsulation.None,
})


export class ProductDetailsComponent {
  loading$!: Observable<boolean>;
  showLoader: boolean = true;
  product: any;
  isLoading = false;
  flex!: any;
  items$ = this.cartService.itemsInCart$;
  hasItems: Observable<boolean>;
  onInit: any;

  showFiller = false;
  constructor(
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private router: Router,
    private productsService: ProductsService,
    public activeModal: NgbActiveModal,
    private location: PlatformLocation,
    private cartService: CartService,
    private _snackBar: MatSnackBar,
    private loaderService: LoaderService,
  ) {
    location.onPopState(() => this.modalService.dismissAll());
    this.hasItems = this.cartService.itemsInCartCount$.pipe(map(result => !!result));
  }

  images!: any;
  loaded = 0;
  finish = new Observable;
  fromFavorites!: string;

  ngOnInit(): void {
    this.handleClick();
    //Set loading to sale
    this.loading$ = this.loaderService.loading$;

    this.loaderService.showLoader();

    this.flex = (window.innerWidth <= 768) ? 'flex-column-reverse flex-lg-row' : '';

    this.cartService.loadCart();
    this.loadImages(this.product.images);

    let id = parseInt(localStorage.getItem('lastId') ?? '15');


    window.addEventListener("orientationchange", () => {
      this.loaderService.showLoader();
      setTimeout(() => {
        this.flex = (window.innerWidth <= 768) ? 'flex-column-reverse flex-lg-row' : '';
        this.loaderService.hideLoader();
      }, 300);
    });


    setTimeout(() => {
      this.loaderService.hideLoader();
    }, 3000);

    this.route.queryParams
      .subscribe(params => {
          console.log(params)
          this.fromFavorites = params['fromFavorites'];

          if(this.fromFavorites) {
            //Set background image
            let background = 'assets/images/background/home/h.jpg';
            if (window.innerHeight > window.innerWidth) {
              background = 'assets/images/background/home/v.jpg';
            }

            var myCss = ".modal-content { background:url('" + background + "') no-repeat center center fixed;}";
            var style = document.createElement("STYLE");
            style.innerText = myCss;
            document.body.appendChild(style);
          } else {

            if (id) {
              this.setBackgroundImage(id);
            } else {
              id = parseInt('' + this.route.snapshot.paramMap.get('id'));
              this.setBackgroundImage(id);
            }
          }

        }
      );

  }


  private loadImages(images: any) {
    for (let image of images) {
      let img = new Image();
      img.onload = () => {
        this.allLoaded(images);
      }
      img.src = image.image1200Url;
    }
  }

  private loadImages800(images: any) {
    for (let image of images) {
      let img = new Image();
      img.onload = () => {
        this.allLoaded(images);
      }
      img.src = image.image1200Url;
    }
  }

  private allLoaded(images: any): void {
    this.loaded++;
    if (images.length == this.loaded) {
      //all images loaded
      this.loaderService.hideLoader();
      return;
    }
  }


  private setBackgroundImage(id: number) {

    //Set background image
    let background = 'assets/images/background/' + id + '/h.jpg';

    if (window.innerHeight > window.innerWidth) {
      background = 'assets/images/background/' + id + '/v.jpg';
    }

    var myCss = ".modal-content { background:url('" + background + "') no-repeat center center fixed;}";
    var style = document.createElement("STYLE");
    style.innerText = myCss;
    document.body.appendChild(style);

  }

  onResize(event: any) {
    this.flex = (window.innerWidth <= 768) ? 'flex-column-reverse flex-lg-row' : '';
  }

  close() {
    this.modalService.dismissAll()
  }

  onPan(evt: any) {
    const url = this.router.createUrlTree([], {relativeTo: this.route, queryParams: {showProduct: ''}}).toString()
    this.router.navigateByUrl(url, {skipLocationChange: false});
    this.modalService.dismissAll()
  }

  isInMyFavorite(product: ProductModel) {
    if (!this.cartService.itemInCart(product)) {
      return false;
    } else {
      return true;
    }
  }


  addToCart(product: ProductModel) {


    if (!this.cartService.itemInCart(product)) {
      this.cartService.addToCart(product);
      this.modalService.dismissAll()
      this.openSnackBar(product);

    } else {
      this.cartService.deleteItemInCart(product);
      this.modalService.dismissAll()

      let count = this.cartService.getItems();
      //this.cartService.clearCart();

      this.openSnackBarDelete(product);

    }
  }


  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  Object = Object;

  settings = {
    counter: true,
    closable:true,
    controls:true,
    download:false,
    enableSwipe:true,
    mobileSettings: {
      controls: true, // Hide arrow buttons
    },
    selector: ".item",
    closeOnTap: true,
    addClass: 'galleryDetail'
  };

  openSnackBar(product: any) {
    this._snackBar.open(product.name + " à été ajouté dans vos favoris", '', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 2000
    });
  }


  openSnackBarDelete(product: any) {
    this._snackBar.open(product.name + " à été supprimé dans vos favoris", '', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 2000
    });
  }


  @ViewChild('drawer') drawer!: MatDrawer;
  clickCount: number = 0;
  delayBetweenClicks: number = 500; // 500 millisecondes de délai entre les clics
  lastClickTime: number = 0;

  handleClick() {
    const currentTime = Date.now();

    if (currentTime - this.lastClickTime <= this.delayBetweenClicks) {
      this.clickCount++;
    } else {
      this.clickCount = 1;
    }

    if (this.clickCount === 5) {
      this.drawer.toggle();
      this.clickCount = 0;
    }

    this.lastClickTime = currentTime;
  }
}
