
<mat-spinner *ngIf="(loading$ | async); else showContent" [diameter]="80" [strokeWidth]="3"></mat-spinner>

<mat-drawer #drawer class="example-sidenav" mode="side" >



  <h2 (click)="drawer.toggle()">Close</h2>

  <pre>{{ product | json}}</pre>
</mat-drawer>




<ng-template #showContent>

  <!-- This for autofocus -->
  <a href=""></a>

  <mat-icon (click)="activeModal.dismiss('Cross click')" class="closeIcon">close</mat-icon>


  <div class="appModalContainer"  (click)="handleClick()">
    <div class="container-modal">

      <div class="row">
        <div class="col-12" style="position: relative">
          <h1>{{product.name}}</h1>
        </div>
      </div>

      <div class="row {{flex}}">
        <div class="col-sm-12 col-lg-4" style="position: relative">
          <div class="verticalContainer">
            <div class="content">

              <div *ngFor="let key of Object.keys( product.featureGroup)">
                <strong>{{key | titlecase}} :</strong>
                <ul>
                  <li *ngFor="let feature of product.featureGroup[key]">
                    {{feature | titlecase}}
                  </li>
                </ul>
              </div>

              <div  *ngIf="product.reference">
                <strong>Réference :</strong>
              </div>
              <ul  *ngIf="product.reference">
                <li>{{product.reference}}</li>
              </ul>

            </div>
          </div>
        </div>
        <div class="col-sm-12 col-lg-8">

          <button *ngIf="!isInMyFavorite(product)" class="addToFavorites" (click)="addToCart(product)" >
            <img src="assets/images/like.png" width="50"/>
            <span>J'aime</span>
          </button>

          <button *ngIf="isInMyFavorite(product)" class="addToFavorites" (click)="addToCart(product)" >
            <img src="assets/images/unlike.png" width="80"/>
            <span>Retirer des favoris</span>
          </button>



          <lightgallery [settings]="settings" [onInit]="onInit"  >
          <swiper
            class="mySwiper2"
            style="--swiper-navigation-color: #fff;--swiper-pagination-color: #fff"
            [direction]="'horizontal'"
            [spaceBetween]="0"
            [rewind]="false"
            [zoom]="false"
            [lazy]="false"
            [navigation]="false"
            [pagination]="{
            clickable: true
            }"
          >
              <ng-template swiperSlide *ngFor="let image of product.images" >
                <a href="{{ image.imageUrl }}">
                  <img
                    width="100%"
                    height="auto"
                    class="item"
                    *ngIf="product.default_image"
                    src="{{image.image1200Url}}"
                  />
                </a>
              </ng-template>
          </swiper>
          </lightgallery>

        </div>
      </div>
    </div>
  </div>
</ng-template>



